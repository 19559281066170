import React from "react";

const ChangePasswordPage = () => {
  return (
    <div>
      <h1 style={{paddingTop:200, margin: 'auto', width: '50%'}}>Change Password ...</h1>
    </div>
  );
};

export default ChangePasswordPage;
